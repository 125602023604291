const customLabelsEs = require('./custom-languages/custom_es.js');
const customLabelsJa = require('./custom-languages/custom_ja.js');
const customLabelsFr = require('./custom-languages/custom_fr.js');
const customLabelsKo = require('./custom-languages/custom_ko.js');
const customLabelsDe = require('./custom-languages/custom_de.js');
const customLabelsEn = require('./custom-languages/custom_en.js');
const customLabelsPtBr = require('./custom-languages/custom_pt-br.js');
const customLabelsZhCn = require('./custom-languages/custom_zh-cn.js');

const customLabels = {
  'myf5.registration.form.submit': 'Save profile information',
  'myf5.registration.form.submit.button': 'Complete registration',
  'myf5.registration.form.subtitle':
    'You successfully logged into your F5 account! Please provide additional information below so we can update your profile. Your Information is private & safe.',
  'myf5.registration.form.title': 'Account Information',
  'errors.E0000004': 'The information does not match our records.',
};

let midsNewUIFeatureToggle = localStorage.getItem('newUIFeature');
if (midsNewUIFeatureToggle === null) {
  midsNewUIFeatureToggle = true;
} else {
  midsNewUIFeatureToggle = midsNewUIFeatureToggle === 'true';
}
if (midsNewUIFeatureToggle) {
  customLabels['mfa.factors.dropdown.sr.text'] = '{0}';
  customLabels['registration.complete.title'] = 'Thank you';
}

let languagesOverride = {
  // Overriding English properties
  'en': {
    ...customLabelsEn,
    ...customLabels,
  },
  'cs': { ...customLabels,
          ...customLabelsEn },
  'da': { ...customLabels,
          ...customLabelsEn },
  'de': {
    ...customLabelsDe,
    ...customLabels
  }, // F5 Supported
  'el': { ...customLabels,
          ...customLabelsEn },
  'es': {
    ...customLabelsEs,
    ...customLabels
  }, // F5 Supported
  'fi': { ...customLabels,
          ...customLabelsEn },
  'fr': {
    ...customLabelsFr,
    ...customLabels
  }, // F5 Supported
  'hu': { ...customLabels,
    ...customLabelsEn },
  'id': { ...customLabels,
    ...customLabelsEn },
  'it': { ...customLabels,
    ...customLabelsEn },
  'in': { ...customLabels,
    ...customLabelsEn },
  'ja': {
    ...customLabelsJa,
    ...customLabels
  }, // F5 Supported
  'ko': {
    ...customLabelsKo,
    ...customLabels
  }, // F5 Supported
  'ms': { ...customLabels,
    ...customLabelsEn },
  'nb': { ...customLabels,
    ...customLabelsEn },
  'pl': { ...customLabels,
    ...customLabelsEn },
  'ro': { ...customLabels,
    ...customLabelsEn },
  'ru': { ...customLabels,
          ...customLabelsEn },
  'sv': { ...customLabels,
    ...customLabelsEn },
  'th': { ...customLabels,
    ...customLabelsEn },
  'tr': { ...customLabels,
    ...customLabelsEn },
  'uk': { ...customLabels,
    ...customLabelsEn },
  'vi': { ...customLabels,
    ...customLabelsEn },
  'nl-NL': { ...customLabels,
    ...customLabelsEn },
  'pt-BR': {
    ...customLabelsPtBr,
    ...customLabels
  }, // F5 Supported
  'zh-CN': {
    ...customLabelsZhCn,
    ...customLabels
  }, // F5 Supported
  'zh-TW': { ...customLabels,
    ...customLabelsEn },
  'ok-PL': { ...customLabels,
    ...customLabelsEn },
};

function addLanguageOverride(language, textId, text) {
  languagesOverride[language][textId] = text;
}

function emailNotUniqueInOrgBug() {
  const textId = 'registration.error.userName.notUniqueWithinOrg';
  addLanguageOverride(
    'cs',
    textId,
    '\u00DA\u010Det s t\u00EDmto u\u017Eivatelsk\u00FDm jm\u00E9nem ({0}) u\u017E existuje.'
  );
  addLanguageOverride('da', textId, 'Der findes allerede en konto med {0}');
  addLanguageOverride(
    'de',
    textId,
    'Ein Konto mit diesem {0} existiert bereits'
  );
  addLanguageOverride(
    'el',
    textId,
    '\u03A5\u03C0\u03AC\u03C1\u03C7\u03B5\u03B9 \u03AE\u03B4\u03B7 \u03BB\u03BF\u03B3\u03B1\u03C1\u03B9\u03B1\u03C3\u03BC\u03CC\u03C2 \u03BC\u03B5 \u03B1\u03C5\u03C4\u03CC \u03C4\u03BF {0}'
  );
  addLanguageOverride('es', textId, 'Ya existe una cuenta con esa {0}');
  addLanguageOverride(
    'fi',
    textId,
    'Tili, jolla on t\u00E4m\u00E4 {0}, on jo olemassa'
  );
  addLanguageOverride(
    'fr',
    textId,
    'Ce {0} est d\u00E9j\u00E0 utilis\u00E9 pour un autre compte'
  );
  addLanguageOverride(
    'hu',
    textId,
    'M\u00E1r l\u00E9tezik egy fi\u00F3k a(z) {0} felhaszn\u00E1l\u00F3n\u00E9vvel'
  );
  addLanguageOverride('id', textId, 'Akun dengan {0} sudah ada');
  addLanguageOverride('it', textId, 'Esiste gi\u00E0 un account con tale {0}');
  addLanguageOverride('in', textId, 'Akun dengan {0} sudah ada');
  addLanguageOverride(
    'ja',
    textId,
    '\u305D\u306E {0} \u3092\u6301\u3064\u30A2\u30AB\u30A6\u30F3\u30C8\u306F\u65E2\u306B\u5B58\u5728\u3057\u307E\u3059'
  );
  addLanguageOverride(
    'ko',
    textId,
    '\uC774 {0}\uC744(\uB97C) \uC0AC\uC6A9\uD558\uB294 \uACC4\uC815\uC774 \uC774\uBBF8 \uC788\uC2B5\uB2C8\uB2E4.'
  );
  addLanguageOverride('ms', textId, 'Akaun dengan {0} tersebut sudah wujud');
  addLanguageOverride('nb', textId, 'En konto med den {0} eksisterer allerede');
  addLanguageOverride(
    'pl',
    textId,
    'Konto z tym elementem ({0}) ju\u017C istnieje'
  );
  addLanguageOverride('ro', textId, 'Exist\u0103 deja un cont cu {0}');
  addLanguageOverride(
    'ru',
    textId,
    '\u0423\u0447\u0435\u0442\u043D\u0430\u044F \u0437\u0430\u043F\u0438\u0441\u044C \u0441 {0} \u0443\u0436\u0435 \u0441\u0443\u0449\u0435\u0441\u0442\u0432\u0443\u0435\u0442'
  );
  addLanguageOverride('sv', textId, 'Ett konto med det {0} finns redan');
  addLanguageOverride(
    'th',
    textId,
    '\u0E21\u0E35\u0E1A\u0E31\u0E0D\u0E0A\u0E35\u0E17\u0E35\u0E48\u0E21\u0E35 {0} \u0E2D\u0E22\u0E39\u0E48\u0E41\u0E25\u0E49\u0E27'
  );
  addLanguageOverride('tr', textId, 'Bu {0} ile bir hesap zaten mevcut');
  addLanguageOverride(
    'uk',
    textId,
    '\u041E\u0431\u043B\u0456\u043A\u043E\u0432\u0438\u0439 \u0437\u0430\u043F\u0438\u0441 \u0456\u0437 \u0442\u0430\u043A\u0438\u043C {0} \u0443\u0436\u0435 \u0456\u0441\u043D\u0443\u0454'
  );
  addLanguageOverride(
    'vi',
    textId,
    'T\u00E0i kho\u1EA3n c\u00F3 {0} \u0111\u00F3 \u0111\u00E3 t\u1ED3n t\u1EA1i'
  );
  addLanguageOverride(
    'nl-NL',
    textId,
    'Er bestaat al een account met deze {0}'
  );
  addLanguageOverride('pt-BR', textId, 'Uma conta com esse {0} j\u00E1 existe');
  addLanguageOverride(
    'zh-CN',
    textId,
    '\u5DF2\u5B58\u5728\u5177\u6709\u8BE5 {0} \u7684\u5E10\u6237'
  );
  addLanguageOverride(
    'zh-TW',
    textId,
    '\u8A72 {0} \u7684\u5E33\u6236\u5DF2\u5B58\u5728'
  );
}

function getLanguagesOverride() {
  emailNotUniqueInOrgBug();
  return languagesOverride;
}

module.exports = { getLanguagesOverride };
