import React, { useEffect } from 'react';
import startWidget from '../../okta/main';
import Colorbar from '../shared/Colorbar';

const WidgetContainer = ({ settings, midsNewUIFeatureToggle }) => {
  useEffect(() => {
    if (Object.keys(settings).length !== 0) {
      window.ENVIRONMENT_CONFIG = settings;
      startWidget();
    }
  }, [settings]);

  const widgetCssClass = midsNewUIFeatureToggle ? 'f5-widget-ui' : 'ui-widget';

  return (
    <div className={widgetCssClass} id='widget-section' dir='ltr'>
      <div className='page-header-text slds-m-top_medium slds-text-align_center'>
        <div className='container' style={{ maxWidth: '100%' }}>
          <div className='col login-section'>
            {/* TODO pre widget body*/}
            <div id='okta-login-container' />
          </div>
        </div>
      </div>
      {midsNewUIFeatureToggle? <Colorbar /> : null}
    </div>
  );
};

export default WidgetContainer;
