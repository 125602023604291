// Simplified Chinese
module.exports = {
  'registration.form.title': '创建 F5 帐户',
  'registration.form.subtitle': '即刻注册，即可访问个性化内容、下载和资源，并轻松管理您的所有产品。',
  'registration.form.submit': '注册',
  'registration.complete.desc': '恭喜您已创建 F5 帐户！请通过 {0} 检查您的电子邮件，并点击链接以进行验证并完成注册。',
  'mids.signin.title': '欢迎回来！',
  'mids.signin.subtitle': '登录以继续访问 {0}',
  'primaryauth.username.placeholder': '电子邮件',
  'primaryauth.username.input': '输入电子邮件',
  'primaryauth.password.input': '输入密码',
  'enroll.choices.title': '设置两步身份验证',
  'enroll.choices.description': '安全无小事。保护您的帐户免受网络威胁。',
  'mids.mfa.enroll.google.title': '设置 Google Authenticator',
  'mids.mfa.enroll.google.subtitle': '通过二维码或字母数字编码连接至应用程序。',
  'enroll.totp.mids.download.label': '下载 {0}',
  'enroll.totp.mids.downloadApp': '在您的移动设备上，<b>通过 {1} 下载 {0}</b>',
  'mids.download': '下载',
  'mids.enroll.google.scanBarcode.description': '启动 {0}，点击“+”图标，然后选择“扫描条形码”。',
  'enroll.totp.cannotScan': '或者输入设置密钥',
  'enroll.totp.manualStep.one': '在 Google Authenticator 应用程序中，轻按“+”，然后按 <b>输入设置密钥</b>。',
  'enroll.totp.manualStep.two': '输入您的电子邮件地址和此密钥（空格无关紧要）：<b>{0}</b>',
  'enroll.totp.manualStep.three': '确保选择<b>基于时间</b>。',
  'enroll.totp.manualStep.four': '轻按 <b>添加</b>以完成。',
  'mids.close': '关闭',
  'mids.mfa.enroll.email.title': '设置电子邮件身份验证',
  'email.enroll.description': '通过电子邮件接收验证码。',
  'email.button.send': '发送验证码',
  'email.setup.description': '输入发送至 {0} 的验证码。',
  'email.setup.placeholder': '输入您的验证码',
  'email.button.resend': '重新发送验证码',
  'mfa.verify.email.title': '使用电子邮件身份验证进行验证',
  'mfa.verify.email.description': '发送验证码至 {0}',
  'mfa.verification.email.placeholder': '输入您的验证码',
  'mfa.challenge.enterCode.placeholder': '使用 Google Authenticator 进行验证',
  'mfa.verify.google.title': '在应用程序中查看密码',
  'mfa.verify.google.subtitle': '验证码',
  'password.forgot.email.or.username.tooltip': '电子邮件',
  'password.forgot.email.or.username.placeholder': '输入电子邮件',
  'password.forgot.emailSent.title': '电子邮件已发送！',
  'password.forgot.emailSent.desc': '如果 {0} 与我们的记录相符，我们将会发送一封电子邮件，内含如何重置密码的说明。',
}