module.exports = {
  'registration.form.title': 'Crie sua conta da F5',
  'registration.form.subtitle': 'Obtenha acesso a conteúdo, downloads e recursos personalizados e gerencie todas as suas ofertas com facilidade inscrevendo-se hoje mesmo.',
  'registration.form.submit': 'Inscreva-se',
  'registration.complete.desc': 'Parabéns por criar sua conta da F5! Verifique seu e-mail em {0} e clique no link para verificar e concluir sua inscrição.',
  'mids.signin.title': 'Bem-vindo(a) de volta!',
  'mids.signin.subtitle': 'Inicie uma sessão para avançar para o {0}',
  'primaryauth.username.placeholder': 'E-mail',
  'primaryauth.username.input': 'Digite seu e-mail',
  'primaryauth.password.input': 'Digite sua senha',
  'enroll.choices.title': 'Configurar autenticação em duas etapas',
  'enroll.choices.description': 'Não comprometa a segurança. Proteja sua conta contra ameaças cibernéticas.',
  'mids.mfa.enroll.google.title': 'Configurar o Google Authenticator',
  'mids.mfa.enroll.google.subtitle': 'Conecte-se à aplicação via código QR ou alfanumérico.',
  'enroll.totp.mids.download.label': 'Baixar o {0}',
  'enroll.totp.mids.downloadApp': '<b>Baixar o {0} da {1}</b> para o seu dispositivo móvel',
  'mids.download': 'Baixar',
  'mids.enroll.google.scanBarcode.description': 'Inicie o {0}, toque no ícone "+" e, em seguida, selecione "Digitalizar código de barras".',
  'enroll.totp.cannotScan': 'Ou digite a chave de configuração',
  'enroll.totp.manualStep.one': 'Na aplicação Google Authenticator, toque em "+" e, depois, toque em <b>Digitar uma chave de configuração</b>.',
  'enroll.totp.manualStep.two': 'Digite seu endereço de e-mail e esta chave (espaços não importam): <b>{0}</b>',
  'enroll.totp.manualStep.three': 'Certifique-se de que a opção <b>Baseado em tempo</b> esteja selecionada.',
  'enroll.totp.manualStep.four': 'Toque em <b>Adicionar</b> para concluir.',
  'mids.close': 'Fechar',
  'mids.mfa.enroll.email.title': 'Configurar autenticação por e-mail',
  'email.enroll.description': 'Receba um código de verificação por e-mail.',
  'email.button.send': 'Enviar código',
  'email.setup.description': 'Digite o código que foi enviado para {0}.',
  'email.setup.placeholder': 'Digite seu código',
  'email.button.resend': 'Reenviar código',
  'mfa.verify.email.title': 'Verificar com autenticação por e-mail',
  'mfa.verify.email.description': 'Enviar um código de verificação para {0}',
  'mfa.verification.email.placeholder': 'Digite seu código',
  'mfa.challenge.enterCode.placeholder': 'Verificar com o Google Authenticator',
  'mfa.verify.google.title': 'Visualizar código de acesso na aplicação',
  'mfa.verify.google.subtitle': 'Código de verificação',
  'password.forgot.email.or.username.tooltip': 'E-mail',
  'password.forgot.email.or.username.placeholder': 'Digite seu e-mail',
  'password.forgot.emailSent.title': 'E-mail enviado!',
  'password.forgot.emailSent.desc':
    'Se {0} apresentou correspondência em nossos registros, um e-mail foi enviado com instruções sobre como redefinir sua senha.',
}