module.exports = {
  'registration.form.title': 'Créer votre compte F5',
  'registration.form.subtitle': "Accédez à des contenus, des téléchargements et des ressources personnalisés, et gérez facilement toutes vos offres en vous inscrivant dès aujourd'hui.",
  'registration.form.submit': "S'inscrire",
  'registration.complete.desc': "Félicitations pour la création de votre compte F5 ! Veuillez consulter votre courrier électronique à l'adresse {0} et cliquer sur le lien pour vérifier et compléter votre inscription.",
  'mids.signin.title': 'Bienvenue !',
  'mids.signin.subtitle': 'Connectez-vous pour continuer sur {0}',
  'primaryauth.username.placeholder': 'E-mail',
  'primaryauth.username.input': 'Saisissez votre adresse électronique',
  'primaryauth.password.input': 'Entrez votre mot de passe',
  'enroll.choices.title': "Configurer l'authentification en 2 étapes",
  'enroll.choices.description': 'Ne faites pas de compromis sur votre sécurité : protégez votre compte contre les cybermenaces.',
  'mids.mfa.enroll.google.title': 'Configurer Google Authenticator',
  'mids.mfa.enroll.google.subtitle': "Se connecter à l'application via un code QR ou alphanumérique.",
  'enroll.totp.mids.download.label': 'Télécharger {0}',
  'enroll.totp.mids.downloadApp': "<b>Téléchargez {0} depuis l'{1}</b> sur votre appareil mobile",
  'mids.download': 'Télécharger',
  'mids.enroll.google.scanBarcode.description': "Lancez {0}, appuyez sur l'icône « + », puis sélectionnez « Scanner le code-barres »",
  'enroll.totp.cannotScan': 'Ou entrez la clé de configuration',
  'enroll.totp.manualStep.one': "Dans l'application Google Authenticator, appuyez sur le +, puis sur <b>Entrer une clé de configuration</b>.",
  'enroll.totp.manualStep.two': "Saisissez votre adresse électronique et la clé suivante (les espaces n'ont pas d'importance) : <b>{0}</b>",
  'enroll.totp.manualStep.three': "Assurez-vous que l'option <b>Basé sur le temps</b> est sélectionnée.",
  'enroll.totp.manualStep.four': 'Appuyez sur <b>Ajouter</b> pour terminer.',
  'mids.close': 'Fermer',
  'mids.mfa.enroll.email.title': "Configurer l'authentification par courrier électronique",
  'email.enroll.description': 'Recevoir un code de vérification par e-mail.',
  'email.button.send': 'Envoyer le code',
  'email.setup.description': 'Saisissez le code qui a été envoyé à {0}.',
  'email.setup.placeholder': 'Entrez votre code',
  'email.button.resend': 'Renvoyer le code',
  'mfa.verify.email.title': "Vérifier avec l'authentification par courrier électronique",
  'mfa.verify.email.description': 'Envoyer un code de vérification à {0}',
  'mfa.verification.email.placeholder': 'Entrez votre code',
  'mfa.challenge.enterCode.placeholder': 'Vérifier avec Google Authenticator',
  'mfa.verify.google.title': "Afficher le code d'accès dans l'application",
  'mfa.verify.google.subtitle': 'Code de vérification',
  'password.forgot.email.or.username.tooltip': 'E-mail',
  'password.forgot.email.or.username.placeholder': 'Saisissez votre adresse électronique',
  'password.forgot.emailSent.title': 'E-mail envoyé !',
  'password.forgot.emailSent.desc':
    "Si l'adresse {0} correspond aux données en notre possession, un e-mail a été envoyé avec les instructions nécessaires pour réinitialiser votre mot de passe.",
};