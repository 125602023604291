import React, { useState } from 'react';
import { widgetsInfo } from '../../helpers/widgetsInfo';

const Header = ({ page, midsNewUIFeatureToggle }) => {
  if (midsNewUIFeatureToggle) {
    const [showDropdow, setShowDropdown] = useState(false);

    const dropdownElements = {
      'DevCentral': {
        desc: 'Connect & learn in our hosted community',
        url: 'https://devcentral.f5.com/',
      },
      'F5 Labs': {
        desc: 'The latest threat intel and research to help protect your apps',
        url: 'https://www.f5.com/labs',
      },
      'MyF5': {
        desc: 'Your key to everything F5, including support, registration keys, and subscriptions',
        url: 'https://my.f5.com/',
      },
      'Partner Central': {
        desc: 'Research and support for partners',
        url: 'https://partnercentral.f5.com/',
      },
      'LearnF5': {
        desc: 'Guidance, insights, and how to use F5 products',
        url: 'https://account.f5.com/learnf5',
      },
    }

    const dropdownRows = [];

    for (let key in dropdownElements) {
      let description = dropdownElements[key].desc;
      let url = dropdownElements[key].url;
      dropdownRows.push(
        <div className='dropdown-row' key={key}>
          <a id='dropdown-row-title' href={url}>{key}</a>
          <p id='dropdown-row-desc'> {description}</p>
        </div>
      )
    }

    const dropdownClick = () => {
      setShowDropdown(!showDropdow);
    };

    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        setShowDropdown(!showDropdow);
      }
    }

    return (
      <div className='f5-header-ui'>
        <div className='f5-header-body'>
          <div id='dropdown-wrapper' className='dropdown-wrapper' >
            <div
              className='dropdown-toggle'
              tabIndex={0}
              onClick={dropdownClick}
              onKeyDown={handleKeyDown}
            >
              <span>F5 Sites</span>
              <span className='f5-dropdown-icon'>
                <img src='/img/icons/chevron-down.svg'></img>
              </span>
            </div>
            {showDropdow ?
              <div id='dropdown-body' className='dropdown-body'>
                { dropdownRows }
              </div>
              :
              null
            }
          </div>
        </div>
      </div>
    );
  }
  else {
    const nav = {
      'F5.COM': 'https://www.f5.com/',
      'DEVCENTRAL': 'https://devcentral.f5.com/',
      'SUPPORT': 'https://support.f5.com/',
      'PARTNERS': 'https://partnercentral.f5.com/',
      'MyF5': 'https://my.f5.com/manage/s/',
    };
    let logoClass =
      widgetsInfo[page].logo.logoClass && widgetsInfo[page].logo.logoClass !== ''
        ? widgetsInfo[page].logo.logoClass
        : 'f5-logo';
    let links = [];
  
    for (const key in nav) {
      links.push(
        <li key={key}>
          <a className='internalLink' href={nav[key]} target='_blank'>
            {key}
          </a>
        </li>
      );
    }
    return (
      <div className='f5-header slds-clearfix nav-down cF5_NonLoggedInComponent'>
        <div className='container'>
          <div className='header-links slds-clearfix desktop-only flex justify-end'>
            <ul className='nav piped slds-float_right slds-list_horizontal slds-m-top_xxx-small'>
              {links}
            </ul>
          </div>
  
          <div className='slds-clearfix slds-p-top_x-small logo-container'>
            <div className='left-container'>
              <div className='logo'>
                <a className='logo-title-link' href={widgetsInfo[page].logo.link}>
                  <img
                    className={logoClass}
                    src={widgetsInfo[page].logo.src}
                  ></img>
                  <h1 className='f5cs-logo-title-header'>
                    {widgetsInfo[page].logo.sideText}
                  </h1>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Header;
