module.exports = {
  "registration.form.title": "Crear su cuenta de F5",
  'registration.form.subtitle': 'Acceda a contenidos, descargas y recursos personalizados, y gestione todas sus ofertas con facilidad registrándose hoy mismo.',
  'registration.form.submit': 'Inscribirse',
  'registration.complete.desc': '¡Felicidades, ha creado su cuenta de F5! Compruebe si ha recibido un correo electrónico en {0} y haga clic en el enlace para verificar y completar el registro.',
  'mids.signin.title': 'Le damos la bienvenida de nuevo',
  'mids.signin.subtitle': 'Inicie sesión para continuar a {0}',
  'primaryauth.username.placeholder': 'Correo electrónico',
  'primaryauth.username.input': 'Introduzca su dirección de correo electrónico',
  'primaryauth.password.input': 'Introduzca su contraseña',
  'enroll.choices.title': 'Configurar la autenticación en dos pasos',
  'enroll.choices.description': 'No haga concesiones en materia de seguridad: proteja su cuenta contra las ciberamenazas.',
  'mids.mfa.enroll.google.title': 'Configurar Google Authenticator',
  'mids.mfa.enroll.google.subtitle': 'Conéctese a la aplicación mediante un código QR o alfanumérico.',
  'enroll.totp.mids.download.label': 'Descargar {0}',
  'enroll.totp.mids.downloadApp': '<b>Descargue {0} desde la {1}</b> en su dispositivo móvil',
  'mids.download': 'Descargar',
  'mids.enroll.google.scanBarcode.description': 'Inicie {0}, toque el icono "+" y seleccione "Escanear código de barras".',
  'enroll.totp.cannotScan': 'También puede introducir la clave de configuración',
  'enroll.totp.manualStep.one': 'En la aplicación Google Authenticator, toque "+" y después <b>Introducir una clave proporcionada</b>.',
  'enroll.totp.manualStep.two': 'Introduzca su dirección de correo electrónico y esta clave (se pueden omitir los espacios): <b>{0}</b>',
  'enroll.totp.manualStep.three': 'Compruebe que esté seleccionada la opción <b>Basada en tiempo</b>.',
  'enroll.totp.manualStep.four': 'Toque <b>Añadir</b> para terminar.',
  'mids.close': 'Cerrar',
  'mids.mfa.enroll.email.title': 'Configurar la autenticación de correo electrónico',
  'email.enroll.description': 'Reciba un código de verificación por correo electrónico.',
  'email.button.send': 'Enviar código',
  'email.setup.description': 'Introduzca el código enviado a {0}.',
  'email.setup.placeholder': 'Introduzca su código',
  'email.button.resend': 'Reenviar código',
  'mfa.verify.email.title': 'Verificar con autenticación de correo electrónico',
  'mfa.verify.email.description': 'Enviar un código de verificación a {0}',
  'mfa.verification.email.placeholder': 'Introduzca su código',
  'mfa.challenge.enterCode.placeholder': 'Verificar con Google Authenticator',
  'mfa.verify.google.title': 'Ver el código de acceso en la aplicación',
  'mfa.verify.google.subtitle': 'Código de verificación',
  'password.forgot.email.or.username.tooltip': 'Correo electrónico',
  'password.forgot.email.or.username.placeholder': 'Introduzca su dirección de correo electrónico',
  'password.forgot.emailSent.title': '¡Correo enviado!',
  'password.forgot.emailSent.desc':
    'Si {0} coincide con nuestros registros, se habrá enviado un correo electrónico con instrucciones para restablecer su contraseña.',
};