module.exports = [
  { const: "US", title: "United States of America" },
  { const: "AF", title: "Afghanistan" },
  { const: "AX", title: "Åland Islands" },
  { const: "AL", title: "Albania" },
  { const: "DZ", title: "Algeria" },
  { const: "AS", title: "American Samoa" },
  { const: "AD", title: "Andorra" },
  { const: "AO", title: "Angola" },
  { const: "AI", title: "Anguilla" },
  { const: "AQ", title: "Antarctica" },
  { const: "AG", title: "Antigua and Barbuda" },
  { const: "AR", title: "Argentina" },
  { const: "AM", title: "Armenia" },
  { const: "AW", title: "Aruba" },
  { const: "AU", title: "Australia" },
  { const: "AT", title: "Austria" },
  { const: "AZ", title: "Azerbaijan" },
  { const: "BS", title: "Bahamas" },
  { const: "BH", title: "Bahrain" },
  { const: "BD", title: "Bangladesh" },
  { const: "BB", title: "Barbados" },
  { const: "BY", title: "Belarus" },
  { const: "BE", title: "Belgium" },
  { const: "BZ", title: "Belize" },
  { const: "BJ", title: "Benin" },
  { const: "BM", title: "Bermuda" },
  { const: "BT", title: "Bhutan" },
  { const: "BO", title: "Bolivia (Plurinational State of)" },
  { const: "BQ", title: "Bonaire, Sint Eustatius and Saba" },
  { const: "BA", title: "Bosnia and Herzegovina" },
  { const: "BW", title: "Botswana" },
  { const: "BV", title: "Bouvet Island" },
  { const: "BR", title: "Brazil" },
  { const: "IO", title: "British Indian Ocean Territory" },
  { const: "BN", title: "Brunei Darussalam" },
  { const: "BG", title: "Bulgaria" },
  { const: "BF", title: "Burkina Faso" },
  { const: "BI", title: "Burundi" },
  { const: "CV", title: "Cabo Verde" },
  { const: "KH", title: "Cambodia" },
  { const: "CM", title: "Cameroon" },
  { const: "CA", title: "Canada" },
  { const: "KY", title: "Cayman Islands" },
  { const: "CF", title: "Central African Republic" },
  { const: "TD", title: "Chad" },
  { const: "CL", title: "Chile" },
  { const: "CN", title: "China" },
  { const: "CX", title: "Christmas Island" },
  { const: "CC", title: "Cocos (Keeling) Islands" },
  { const: "CO", title: "Colombia" },
  { const: "KM", title: "Comoros" },
  { const: "CG", title: "Congo" },
  { const: "CD", title: "Congo, Democratic Republic of the" },
  { const: "CK", title: "Cook Islands" },
  { const: "CR", title: "Costa Rica" },
  { const: "CI", title: "Côte d'Ivoire" },
  { const: "HR", title: "Croatia" },
  { const: "CU", title: "Cuba" },
  { const: "CW", title: "Curaçao" },
  { const: "CY", title: "Cyprus" },
  { const: "CZ", title: "Czechia" },
  { const: "DK", title: "Denmark" },
  { const: "DJ", title: "Djibouti" },
  { const: "DM", title: "Dominica" },
  { const: "DO", title: "Dominican Republic" },
  { const: "EC", title: "Ecuador" },
  { const: "EG", title: "Egypt" },
  { const: "SV", title: "El Salvador" },
  { const: "GQ", title: "Equatorial Guinea" },
  { const: "ER", title: "Eritrea" },
  { const: "EE", title: "Estonia" },
  { const: "SZ", title: "Eswatini" },
  { const: "ET", title: "Ethiopia" },
  { const: "FK", title: "Falkland Islands (Malvinas)" },
  { const: "FO", title: "Faroe Islands" },
  { const: "FJ", title: "Fiji" },
  { const: "FI", title: "Finland" },
  { const: "FR", title: "France" },
  { const: "GF", title: "French Guiana" },
  { const: "PF", title: "French Polynesia" },
  { const: "TF", title: "French Southern Territories" },
  { const: "GA", title: "Gabon" },
  { const: "GM", title: "Gambia" },
  { const: "GE", title: "Georgia" },
  { const: "DE", title: "Germany" },
  { const: "GH", title: "Ghana" },
  { const: "GI", title: "Gibraltar" },
  { const: "GR", title: "Greece" },
  { const: "GL", title: "Greenland" },
  { const: "GD", title: "Grenada" },
  { const: "GP", title: "Guadeloupe" },
  { const: "GU", title: "Guam" },
  { const: "GT", title: "Guatemala" },
  { const: "GG", title: "Guernsey" },
  { const: "GN", title: "Guinea" },
  { const: "GW", title: "Guinea-Bissau" },
  { const: "GY", title: "Guyana" },
  { const: "HT", title: "Haiti" },
  { const: "HM", title: "Heard Island and McDonald Islands" },
  { const: "VA", title: "Holy See" },
  { const: "HN", title: "Honduras" },
  { const: "HK", title: "Hong Kong" },
  { const: "HU", title: "Hungary" },
  { const: "IS", title: "Iceland" },
  { const: "IN", title: "India" },
  { const: "ID", title: "Indonesia" },
  { const: "IR", title: "Iran (Islamic Republic of)" },
  { const: "IQ", title: "Iraq" },
  { const: "IE", title: "Ireland" },
  { const: "IM", title: "Isle of Man" },
  { const: "IL", title: "Israel" },
  { const: "IT", title: "Italy" },
  { const: "JM", title: "Jamaica" },
  { const: "JP", title: "Japan" },
  { const: "JE", title: "Jersey" },
  { const: "JO", title: "Jordan" },
  { const: "KZ", title: "Kazakhstan" },
  { const: "KE", title: "Kenya" },
  { const: "KI", title: "Kiribati" },
  { const: "KP", title: "Korea (Democratic People's Republic of)" },
  { const: "KR", title: "Korea, Republic of" },
  { const: "KW", title: "Kuwait" },
  { const: "KG", title: "Kyrgyzstan" },
  { const: "LA", title: "Lao People's Democratic Republic" },
  { const: "LV", title: "Latvia" },
  { const: "LB", title: "Lebanon" },
  { const: "LS", title: "Lesotho" },
  { const: "LR", title: "Liberia" },
  { const: "LY", title: "Libya" },
  { const: "LI", title: "Liechtenstein" },
  { const: "LT", title: "Lithuania" },
  { const: "LU", title: "Luxembourg" },
  { const: "MO", title: "Macao" },
  { const: "MG", title: "Madagascar" },
  { const: "MW", title: "Malawi" },
  { const: "MY", title: "Malaysia" },
  { const: "MV", title: "Maldives" },
  { const: "ML", title: "Mali" },
  { const: "MT", title: "Malta" },
  { const: "MH", title: "Marshall Islands" },
  { const: "MQ", title: "Martinique" },
  { const: "MR", title: "Mauritania" },
  { const: "MU", title: "Mauritius" },
  { const: "YT", title: "Mayotte" },
  { const: "MX", title: "Mexico" },
  { const: "FM", title: "Micronesia (Federated States of)" },
  { const: "MD", title: "Moldova, Republic of" },
  { const: "MC", title: "Monaco" },
  { const: "MN", title: "Mongolia" },
  { const: "ME", title: "Montenegro" },
  { const: "MS", title: "Montserrat" },
  { const: "MA", title: "Morocco" },
  { const: "MZ", title: "Mozambique" },
  { const: "MM", title: "Myanmar" },
  { const: "NA", title: "Namibia" },
  { const: "NR", title: "Nauru" },
  { const: "NP", title: "Nepal" },
  { const: "NL", title: "Netherlands" },
  { const: "NC", title: "New Caledonia" },
  { const: "NZ", title: "New Zealand" },
  { const: "NI", title: "Nicaragua" },
  { const: "NE", title: "Niger" },
  { const: "NG", title: "Nigeria" },
  { const: "NU", title: "Niue" },
  { const: "NF", title: "Norfolk Island" },
  { const: "MK", title: "North Macedonia" },
  { const: "MP", title: "Northern Mariana Islands" },
  { const: "NO", title: "Norway" },
  { const: "OM", title: "Oman" },
  { const: "PK", title: "Pakistan" },
  { const: "PW", title: "Palau" },
  { const: "PS", title: "Palestine, State of" },
  { const: "PA", title: "Panama" },
  { const: "PG", title: "Papua New Guinea" },
  { const: "PY", title: "Paraguay" },
  { const: "PE", title: "Peru" },
  { const: "PH", title: "Philippines" },
  { const: "PN", title: "Pitcairn" },
  { const: "PL", title: "Poland" },
  { const: "PT", title: "Portugal" },
  { const: "PR", title: "Puerto Rico" },
  { const: "QA", title: "Qatar" },
  { const: "RE", title: "Réunion" },
  { const: "RO", title: "Romania" },
  { const: "RU", title: "Russian Federation" },
  { const: "RW", title: "Rwanda" },
  { const: "BL", title: "Saint Barthélemy" },
  { const: "SH", title: "Saint Helena, Ascension and Tristan da Cunha" },
  { const: "KN", title: "Saint Kitts and Nevis" },
  { const: "LC", title: "Saint Lucia" },
  { const: "MF", title: "Saint Martin (French part)" },
  { const: "PM", title: "Saint Pierre and Miquelon" },
  { const: "VC", title: "Saint Vincent and the Grenadines" },
  { const: "WS", title: "Samoa" },
  { const: "SM", title: "San Marino" },
  { const: "ST", title: "Sao Tome and Principe" },
  { const: "SA", title: "Saudi Arabia" },
  { const: "SN", title: "Senegal" },
  { const: "RS", title: "Serbia" },
  { const: "SC", title: "Seychelles" },
  { const: "SL", title: "Sierra Leone" },
  { const: "SG", title: "Singapore" },
  { const: "SX", title: "Sint Maarten (Dutch part)" },
  { const: "SK", title: "Slovakia" },
  { const: "SI", title: "Slovenia" },
  { const: "SB", title: "Solomon Islands" },
  { const: "SO", title: "Somalia" },
  { const: "ZA", title: "South Africa" },
  { const: "GS", title: "South Georgia and the South Sandwich Islands" },
  { const: "SS", title: "South Sudan" },
  { const: "ES", title: "Spain" },
  { const: "LK", title: "Sri Lanka" },
  { const: "SD", title: "Sudan" },
  { const: "SR", title: "Suriname" },
  { const: "SJ", title: "Svalbard and Jan Mayen" },
  { const: "SE", title: "Sweden" },
  { const: "CH", title: "Switzerland" },
  { const: "SY", title: "Syrian Arab Republic" },
  { const: "TW", title: "Taiwan, Province of China" },
  { const: "TJ", title: "Tajikistan" },
  { const: "TZ", title: "Tanzania, United Republic of" },
  { const: "TH", title: "Thailand" },
  { const: "TL", title: "Timor-Leste" },
  { const: "TG", title: "Togo" },
  { const: "TK", title: "Tokelau" },
  { const: "TO", title: "Tonga" },
  { const: "TT", title: "Trinidad and Tobago" },
  { const: "TN", title: "Tunisia" },
  { const: "TR", title: "Turkey" },
  { const: "TM", title: "Turkmenistan" },
  { const: "TC", title: "Turks and Caicos Islands" },
  { const: "TV", title: "Tuvalu" },
  { const: "UG", title: "Uganda" },
  { const: "UA", title: "Ukraine" },
  { const: "AE", title: "United Arab Emirates" },
  { const: "GB", title: "United Kingdom of Great Britain and Northern Ireland" },
  { const: "UM", title: "United States Minor Outlying Islands" },
  { const: "UY", title: "Uruguay" },
  { const: "UZ", title: "Uzbekistan" },
  { const: "VU", title: "Vanuatu" },
  { const: "VE", title: "Venezuela (Bolivarian Republic of)" },
  { const: "VN", title: "Viet Nam" },
  { const: "VG", title: "Virgin Islands (British)" },
  { const: "VI", title: "Virgin Islands (U.S.)" },
  { const: "WF", title: "Wallis and Futuna" },
  { const: "EH", title: "Western Sahara" },
  { const: "YE", title: "Yemen" },
  { const: "ZM", title: "Zambia" },
  { const: "ZW", title: "Zimbabwe" }
]