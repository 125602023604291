import React, {useEffect, useState} from 'react';
import { widgetsInfo } from '../../helpers/widgetsInfo';
import { Helmet } from 'react-helmet';
import Header from './Header';
import Footer from './Footer';
import Disclaimer from './Disclaimer';
import WidgetContainer from './WidgetContainer';
import Error from '../shared/Error';

const Widget = ({ app }) => {
  const [settings, setSettings] = useState({});
  const [midsNewUIFeatureToggle, setMidsNewUIFeatureToggle] = useState(true);
  const getSettings = async () => {
    try {
      const response = await fetch('/config', {
        method: 'GET',
      });
      if (!response.ok) {
        throw 'ERROR';
      }
      let resJson = await response.json();
      return resJson;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  const toggleFoucClass = () => {
    const root = document.getElementById('root');
    if (root.classList.contains('fouc')) {
      root.classList.remove('fouc');
    }
  }

  useEffect(() => {
    const startUpWidget = async () => {
      const settingsResponse = await getSettings();
      setSettings(settingsResponse);
      // Checking if there is a feature toggle in order to redierct to the error
      // page in case the app has the toggle and it is false
      if (settingsResponse[`${app}`.toLocaleLowerCase()] &&
        settingsResponse[`${app}`.toLocaleLowerCase()]['featureToggle'] === 'false') {
        window.location.replace(window.location.origin + '/error');
      }

      appInsightsStart(settingsResponse.base.appInsightsKey);
      const uiFeatureToggle = settingsResponse.base.midsNewUIFeatureToggle;
      setMidsNewUIFeatureToggle(settingsResponse.base.midsNewUIFeatureToggle);
      if (uiFeatureToggle) {
        const newUIFeature = localStorage.getItem('newUIFeature');
        localStorage.setItem('newUIFeature', true);
        if (newUIFeature === null) {
          location.reload();
        }
        setMidsNewUIFeatureToggle(true);
      } else {
        const newUIFeature = localStorage.getItem('newUIFeature');
        if (newUIFeature !== null) {
          localStorage.removeItem('newUIFeature');
          location.reload();
        }
        setMidsNewUIFeatureToggle(false);
      }
      setTimeout(toggleFoucClass(), 1);
    };
    startUpWidget();
  }, []);

  const appInsightsStart = (key) => {
    var appInsights=window.appInsights||function(a){
      function b(a){c[a]=function(){var b=arguments;c.queue.push(function(){c[a].apply(c,b)})}}var c={config:a},d=document,e=window;setTimeout(function(){var b=d.createElement("script");b.src=a.url||"https://az416426.vo.msecnd.net/scripts/a/ai.0.js",d.getElementsByTagName("script")[0].parentNode.appendChild(b)});try{c.cookie=d.cookie}catch(a){}c.queue=[];for(var f=["Event","Exception","Metric","PageView","Trace","Dependency"];f.length;)b("track"+f.pop());if(b("setAuthenticatedUserContext"),b("clearAuthenticatedUserContext"),b("startTrackEvent"),b("stopTrackEvent"),b("startTrackPage"),b("stopTrackPage"),b("flush"),!a.disableExceptionTracking){f="onerror",b("_"+f);var g=e[f];e[f]=function(a,b,d,e,h){var i=g&&g(a,b,d,e,h);return!0!==i&&c["_"+f](a,b,d,e,h),i}}return c
      }({
      instrumentationKey: key,
      enableAutoRouteTracking: true
      });
      window.appInsights=appInsights,appInsights.queue&&0===appInsights.queue.length&&appInsights.trackPageView();
  };
  
  const dataLoader = (app) =>{
    if(Object.keys(settings).length !== 0)
      return {
        ...settings.base,
        ...settings[app],
        spaRootUrl:app!=='root'?app:''
      }
    return {}
  }

  return (
    <div id='f5-widget-wrapper'>
      <Helmet>
        <title>{widgetsInfo[app].title}</title>
        <meta name='description' content={widgetsInfo[app].desc} />
      </Helmet>
      <Header page={app} midsNewUIFeatureToggle={midsNewUIFeatureToggle} />
      {
        app === 'error'?
        <Error />
        :
        <WidgetContainer settings={dataLoader(app)} midsNewUIFeatureToggle={midsNewUIFeatureToggle} />
      }
      <Disclaimer page={app} midsNewUIFeatureToggle={midsNewUIFeatureToggle} />
      <Footer page={app} midsNewUIFeatureToggle={midsNewUIFeatureToggle} />
      <div id='consent_blackbar' style={{position:'fixed',top:'0px',width:'100%'}}/>
    </div>
  );
};

export default Widget;
