/* global OktaSignIn */
/* eslint no-console: 0 */
const startWidget = ()=>{
const oktaFatalErrorNameList = ["CONFIG_ERROR", "UNSUPPORTED_BROWSER_ERROR"];
let RedirectManager = require("./RedirectManager");
const redirectManager = RedirectManager(window.ENVIRONMENT_CONFIG, window.localStorage);
const validateAppUser = require("./validateAppUser");
const oidcRegistrationWithGroup = require("./oidcRegistration");
const validations = require("../../server/util/validations");
const { isInvitation, hasStateToken } = require("./mainHelpers");
const signinWidgetOptions = require('./.widgetrc.js');
const injectReact = require('../helpers/reactInjector').default;
redirectManager.save(window.location.search);
const signIn = new OktaSignIn(signinWidgetOptions);
const registrationUrls = {
    f5labs: '/f5labs/signin/f5labs/register'
};

// Skip SSO validation for Apps mentioned below
const appsWithoutUserValidation = new Set([
    'partnercentral', 'udf', 'community', 'ihealth2', 'advocacy', 'rockwell'
]);

signIn.on('ready', function (context) {
    if (isInvitation()) {
        // disable email editing
        if (context.controller === 'registration')
            document.getElementsByName('email')[0].readOnly = true;
        else if (context.controller === 'idp-discovery' || context.controller === 'primary-auth') {
            document.getElementsByName('username')[0].readOnly = true;
            $('#idp-discovery-submit').trigger('click');
        }
    }
});

signIn.authClient.session.get()
    .then(function (response) {
        // Continue if cleoFeatureToggle is turned off. Setting ENVIRONMENT_CONFIG 
        if(ENVIRONMENT_CONFIG.cleoFeatureToggle === false)
        {
            return response;
        }
        return response;
    })
    .then(function (response) {
        if (response.status === 'ACTIVE' && !window.location.href.includes('/signin') && !isInvitation() && !hasStateToken() && !window.location.href.includes('/profile')) {
            if (ENVIRONMENT_CONFIG.midsLoadingScreen === 'true') injectReact('loading');
            // User has an active SSO session
            let redirectInfo = redirectManager.load();
            if ((ENVIRONMENT_CONFIG.spaRootUrl === 'partnercentral' || redirectInfo.appBaseUrl === 'partnercentral')
                    || (ENVIRONMENT_CONFIG.spaRootUrl === 'advocacy' || redirectInfo.appBaseUrl === 'advocacy')) {
                // All users should be assigned to Partner apps in Okta.

                // NOTE: This link should match the sessionCookieRedirectTpl link in RouterUtil.js. If Okta updates this url, it will need to updated here as well
                let redirectUrl = redirectManager.getSamlUrlFromEnv(redirectInfo);
                let target;
                if (redirectUrl) {
                    target = `${ENVIRONMENT_CONFIG.oktaBaseUrl}/login/sessionCookieRedirect?token=${response.id}&redirectUrl=${encodeURIComponent(redirectUrl)}`;
                } else {
                    target = ENVIRONMENT_CONFIG.f5ErrorPageUrl;
                }

                redirectManager.remove();
                window.location.href = target;
                return;
            } else if (ENVIRONMENT_CONFIG.spaRootUrl === 'udf' || redirectInfo.appBaseUrl === 'udf'
                || ENVIRONMENT_CONFIG.spaRootUrl === 'ihealth2' || redirectInfo.appBaseUrl === 'ihealth2'
                || ENVIRONMENT_CONFIG.spaRootUrl === 'community' || redirectInfo.appBaseUrl === 'community'
                || ENVIRONMENT_CONFIG.spaRootUrl === 'rockwell' || redirectInfo.appBaseUrl === 'rockwell') {
                let redirectUrl = redirectManager.getOidcUrlFromEnv(redirectInfo);
                let target;
                if (redirectUrl) {
                    target = `${ENVIRONMENT_CONFIG.oktaBaseUrl}/login/sessionCookieRedirect?token=${response.id}&redirectUrl=${encodeURIComponent(redirectUrl)}`;
                } else {
                    target = ENVIRONMENT_CONFIG.f5ErrorPageUrl;
                }

                redirectManager.remove();
                window.location.href = target;
                return;
            }

            validateAppUser(response.userId, redirectInfo)
                .then(respBody => {
                    if ((respBody.answer == false && !validations.isEmailF5Domain(response.login)) ||
                        (respBody.answer == false && redirectInfo.appBaseUrl === 'f5labs')) {
                        // User is not in group, or is missing required attributes
                        let app = ENVIRONMENT_CONFIG.spaRootUrl || redirectInfo.appBaseUrl;
                        if (app === 'cloudservices' || app === 'ihealth' || app === 'myprofile') {
                            oidcRegistrationWithGroup(response.login, app)
                            .then(function (regresponse) {
                                let redirectUrl = redirectManager.getOidcUrlFromEnv(redirectInfo);
                                let target;
                                if (redirectUrl) {
                                    target = `${ENVIRONMENT_CONFIG.oktaBaseUrl}/login/sessionCookieRedirect?token=${response.id}&redirectUrl=${encodeURIComponent(redirectUrl)}`;
                                } else {
                                    target = ENVIRONMENT_CONFIG.f5ErrorPageUrl;
                                }

                                redirectManager.remove();
                                window.location.href = target;
                                return;
                            })
                            .catch(function (error) {
                                console.log(error);
                                return;
                            });
                        } else {
                            // redirect to 2nd registration page for SAML Apps
                            console.log(respBody.errorSummary);

                            let urlBase = window.location.origin;
                            let registrationUrl = ENVIRONMENT_CONFIG.spaRootUrl !== '' ? registrationUrls[ENVIRONMENT_CONFIG.spaRootUrl] : registrationUrls[redirectInfo.appBaseUrl];
                            let target = urlBase + registrationUrl;

                            if (app === 'f5labs' && redirectInfo.relayState !== '') {
                                target += `?relaystate=${encodeURIComponent(redirectInfo.relayState)}`;
                            } else if (app === 'cleo') {
                                target = '/cleo/error'
                            } else if (app === 'learnf5') {
                                target = ENVIRONMENT_CONFIG.f5ErrorPageUrl;
                            }

                            window.location.href = target;
                            return;
                        }
                    } else {
                        // User is in the group, and has all required attributes
                        if (ENVIRONMENT_CONFIG.spaRootUrl === 'cloudservices' || redirectInfo.appBaseUrl === 'cloudservices'
                            || ENVIRONMENT_CONFIG.spaRootUrl === 'ihealth' || redirectInfo.appBaseUrl === 'ihealth'
                            || ENVIRONMENT_CONFIG.spaRootUrl === 'f5labs' || redirectInfo.appBaseUrl === 'f5labs'
                            || ENVIRONMENT_CONFIG.spaRootUrl === 'myprofile' || redirectInfo.appBaseUrl === 'myprofile') {
                            // NOTE: This link should match the sessionCookieRedirectTpl link in RouterUtil.js.
                            // If Okta updates this url, it will need to updated here as well
                            let redirectUrl = redirectManager.getOidcUrlFromEnv(redirectInfo);
                            let target;
                            if (redirectUrl) {
                                target = `${ENVIRONMENT_CONFIG.oktaBaseUrl}/login/sessionCookieRedirect?token=${response.id}&redirectUrl=${encodeURIComponent(redirectUrl)}`;
                            } else {
                                target = ENVIRONMENT_CONFIG.f5ErrorPageUrl;
                            }
                            redirectManager.remove();
                            window.location.href = target;
                            return;
                        }

                        let redirectUrl = redirectManager.getSamlUrlFromEnv(redirectInfo);
                        let target;
                        if (redirectUrl) {
                            target = `${ENVIRONMENT_CONFIG.oktaBaseUrl}/login/sessionCookieRedirect?token=${response.id}&redirectUrl=${encodeURIComponent(redirectUrl)}`;
                        } else {
                            target = ENVIRONMENT_CONFIG.f5ErrorPageUrl;
                        }

                        redirectManager.remove();
                        window.location.href = target;
                        return;
                    }
                })
                .catch(error => {
                    console.log(error);
                    return;
                });
        } else {
            // New session need credentials
            signIn.renderEl(
                {
                    el: "#okta-login-container"
                },
    
                async function success(res) {
                    if (res.status === 'SUCCESS' && res.type === 'SESSION_STEP_UP') {
                        if (ENVIRONMENT_CONFIG.midsLoadingScreen === 'true') injectReact('loading');
                        // * Authentication initiated with a state token
                        let redirectInfo = redirectManager.load();
                        redirectManager.remove();
                        let app = '';
                        if(ENVIRONMENT_CONFIG.spaRootUrl){
                            // use app defined in config.js
                            app = ENVIRONMENT_CONFIG.spaRootUrl;
                        } else if (redirectInfo && redirectInfo.appBaseUrl){
                            // use app defined in localStorage, set by RedirectManager
                            app = redirectInfo.appBaseUrl;
                        }

                        // Skip okta group validation for SAML & OIDC apps that allow user in Everyone group
                        if(app === 'advocacy')
                        {
                            res.stepUp.finish();
                            return;
                        }

                        const requiresUserValidation = (!appsWithoutUserValidation.has(app));
                        if (!requiresUserValidation) {
                            // SAML & OIDC apps that allow user in Everyone group
                            res.stepUp.finish();
                            return;
                        }

                        validateAppUser(res.user.id, redirectInfo)
                            .then(async respBody => {
                                if (respBody.answer == false && !validations.isEmailF5Domain(res.user.profile.login)) {
                                    // User is not in group, or is missing required attributes
                                    if (app == 'f5labs') {
                                        // OIDC apps with second registration page
                                        res.stepUp.finish();
                                        return;
                                    } else if (app === 'cloudservices' || app === 'ihealth' || app === 'myprofile') {
                                        oidcRegistrationWithGroup(res.user.profile.login, app)
                                        .then(function (regresponse) {
                                            res.stepUp.finish();
                                            return;
                                        })
                                        .catch(function (error) {
                                            console.log(error);
                                            return;
                                        });
                                    } else {
                                        console.log(respBody.errorSummary);
                                        let urlBase = window.location.origin;
                                        let registrationUrl = ENVIRONMENT_CONFIG.spaRootUrl !== ''
                                            ? registrationUrls[ENVIRONMENT_CONFIG.spaRootUrl]
                                            : registrationUrls[redirectInfo.appBaseUrl];

                                        let target = urlBase + registrationUrl;

                                        window.location.href = target;
                                        return;
                                    }
                                } else {
                                    // User is in the group, and has all required attributes
                                    res.stepUp.finish();
                                    return;
                                }
                            })
                            .catch(error => {
                                console.log(error);
                                return;
                            });
                    }

                    if (res.session) {
                        if (ENVIRONMENT_CONFIG.midsLoadingScreen === 'true') injectReact('loading');
                        // User has completed authentication (res.status === 'SUCCESS')
                        let redirectInfo = redirectManager.load();
                        redirectManager.remove();
                        if(typeof redirectInfo === 'undefined'){
                            redirectInfo = {};
                        }
                        let app = '';
                        if(ENVIRONMENT_CONFIG.spaRootUrl){
                            // use app defined in config.js
                            app = ENVIRONMENT_CONFIG.spaRootUrl;
                        } else if (redirectInfo && redirectInfo.appBaseUrl){
                            // use app defined in localStorage, set by RedirectManager
                            app = redirectInfo.appBaseUrl;
                        }
                        // Redirect to IAM root page, fix for global /signin/password-reset and unknown app to redirect
                        if(!app){
                            res.session.setCookieAndRedirect(window.location.origin);
                            return;
                        }

                        // Checks for normal password reset or account unlock scenario
                        let useLocalStorage = ENVIRONMENT_CONFIG.spaRootUrl === '';
                        const requiresUserValidation = (!appsWithoutUserValidation.has(app));
                        if (!requiresUserValidation) {
                            let redirectUrl = '';
                            if (app === 'partnercentral' || app === 'advocacy') {
                                // All users should be assigned to Partner apps in Okta.
                                redirectUrl = useLocalStorage
                                    ? redirectManager.getSamlUrlFromLocalStorage(redirectInfo)
                                    : redirectManager.getSamlUrlFromEnv(redirectInfo);
                            } else if (app === 'udf' || app === 'community' || app === 'ihealth2' || app === 'rockwell') {
                                redirectUrl = useLocalStorage
                                    ? redirectManager.getOidcUrlFromLocalStorage(redirectInfo)
                                    : redirectManager.getOidcUrlFromEnv(redirectInfo);
                            }

                            if (!redirectUrl) {
                                window.location.href = ENVIRONMENT_CONFIG.f5ErrorPageUrl;
                                return;
                            }

                            res.session.setCookieAndRedirect(redirectUrl);
                            return;
                        }

                        validateAppUser(res.user.id, redirectInfo)
                        .then(async respBody => {
                            if (respBody.answer == false || (app === 'devcentral' && localStorage.getItem('updateProfile'))) {
                                // User is not in group, or is missing required attributes
                                if (app === 'cloudservices' || app === 'ihealth' || app === 'myprofile') {
                                    oidcRegistrationWithGroup(res.user.profile.login, app)
                                    .then(function (regresponse) {
                                        let redirectUrl = useLocalStorage ? redirectManager.getOidcUrlFromLocalStorage(redirectInfo) : redirectManager.getOidcUrlFromEnv(redirectInfo);
                                        if (!redirectUrl) {
                                            window.location.href = ENVIRONMENT_CONFIG.f5ErrorPageUrl;
                                            return;
                                        }
                                        res.session.setCookieAndRedirect(redirectUrl);
                                        return;
                                    })
                                    .catch(function (error) {
                                        console.log(error);
                                        return;
                                    });
                                } else {
                                    console.log(respBody.errorSummary);
                                    let urlBase = window.location.origin;
                                    let registrationUrl = ENVIRONMENT_CONFIG.spaRootUrl !== ''
                                        ? registrationUrls[ENVIRONMENT_CONFIG.spaRootUrl]
                                        : registrationUrls[redirectInfo.appBaseUrl];

                                    if (app === 'f5labs' && redirectInfo.relayState !== '') {
                                        registrationUrl += `?relaystate=${encodeURIComponent(redirectInfo.relayState)}`;
                                    } else if (app === 'learnf5') {
                                        window.location.href = ENVIRONMENT_CONFIG.f5ErrorPageUrl;
                                        return;
                                    }

                                    res.session.setCookieAndRedirect(urlBase + registrationUrl);
                                    return;
                                }
                            } else {
                                // * validateAppUser returned true
                                let redirectUrl = '';
                                if (app === 'cloudservices' || app === 'ihealth' || app === 'f5labs' || app === 'myprofile') {
                                    // * OIDC app
                                    redirectUrl = useLocalStorage
                                        ? redirectManager.getOidcUrlFromLocalStorage(redirectInfo)
                                        : redirectManager.getOidcUrlFromEnv(redirectInfo);
                                } else {
                                    // * SAML app
                                    redirectUrl = useLocalStorage 
                                        ? redirectManager.getSamlUrlFromLocalStorage(redirectInfo)
                                        : redirectManager.getSamlUrlFromEnv(redirectInfo);
                                }

                                if (!redirectUrl) {
                                    window.location.href = ENVIRONMENT_CONFIG.f5ErrorPageUrl;
                                    return;
                                }

                                res.session.setCookieAndRedirect(redirectUrl);
                                return;
                            }
                        })
                        .catch(error => {
                            console.log(error);
                            return;
                        });
                    }
                },

                function error(err) {
                    if (oktaFatalErrorNameList.includes(err.name)) {
                        // Cannot log in, redirect to error page.
                        console.log(err);
                        window.location.href = ENVIRONMENT_CONFIG.f5ErrorPageUrl;
                    }
                }
            );
        }
    })
    .catch(function (error) {
        console.log(error);
    });
}

export default startWidget;