module.exports = {
  'registration.form.title': 'F5アカウントを作成する',
  'registration.form.subtitle':
    'パーソナライズされたコンテンツ、ダウンロード、リソースにアクセスし、すべてのサービスを簡単に管理できるように、ぜひご登録ください。',
  'registration.form.submit': '登録する',
  'registration.complete.desc':
    'F5アカウントが作成されました！{0}宛の電子メールをチェックし、リンクをクリックして確認し、登録を完了してください。',
  'mids.signin.title': 'おかえりなさい！',
  'mids.signin.subtitle': 'サインインして{0}に進んでください',
  'primaryauth.username.placeholder': 'メール',
  'primaryauth.username.input': 'メール アドレスを入力してください',
  'primaryauth.password.input': 'パスワードを入力してください',
  'enroll.choices.title': '2段階認証をセットアップする',
  'enroll.choices.description': 'セキュリティに関して妥協せず、サイバー脅威に対してアカウントの防御を強化しましょう。',
  'mids.mfa.enroll.google.title': 'Google Authenticatorをセットアップする',
  'mids.mfa.enroll.google.subtitle': 'QRコードまたは英数字コードを使用してアプリに接続します。',
  'enroll.totp.mids.download.label': '{0}をダウンロードする',
  'enroll.totp.mids.downloadApp': '<b>{1}から自分のデバイスに{0}をダウンロードする</b>',
  'mids.download': 'ダウンロード',
  'mids.enroll.google.scanBarcode.description': '{0}を起動して、［+］アイコンをタップし、［バーコードをスキャン］を選択します。',
  'enroll.totp.cannotScan': 'または、セットアップ キーを入力してください',
  'enroll.totp.manualStep.one': 'Google Authenticatorアプリで、［+］をタップし、［<b>セットアップ キーを入力する</b>］をタップします。',
  'enroll.totp.manualStep.two': 'メール アドレスと次のキーを入力します（スペースは関係ありません）：<b>{0}</b>',
  'enroll.totp.manualStep.three': '［<b>時間ベース</b>］が選択されていることを確認します。',
  'enroll.totp.manualStep.four': '［<b>追加</b>］をタップして終了します。',
  'mids.close': '閉じる',
  'mids.mfa.enroll.email.title': 'メール認証をセットアップする',
  'email.enroll.description': 'メールで確認コードを受け取ります。',
  'email.button.send': 'コードを送信する',
  'email.setup.description': '{0}に送信されたコードを入力してください。',
  'email.setup.placeholder': 'コードを入力してください',
  'email.button.resend': 'コードを再送信する',
  'mfa.verify.email.title': 'メール認証で確認する',
  'mfa.verify.email.description': '確認コードを{0}に送信します',
  'mfa.verification.email.placeholder': 'コードを入力してください',
  'mfa.challenge.enterCode.placeholder': 'Google Authenticatorで確認する',
  'mfa.verify.google.title': 'アプリでパスコードを表示する',
  'mfa.verify.google.subtitle': '確認コード',
  'password.forgot.email.or.username.tooltip': 'メール',
  'password.forgot.email.or.username.placeholder': 'メール アドレスを入力してください',
  'password.forgot.emailSent.title': 'メール送信済み！',
  'password.forgot.emailSent.desc':
    '{0}が当社の記録と一致している場合は、パスワードのリセット手順を記載した電子メールが送信されました。',
};