function getParameterByName(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(window.location.search);
    return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, " "));
}

function isInvitation() {
    var ref = getParameterByName('ref');
    return ref != null;
}

function hasStateToken() {
    var stateToken = getParameterByName('statetoken');
    return stateToken != null;
}

function getStateToken() {
    var stateToken = getParameterByName('statetoken');
    if (stateToken != null)
        return atob(stateToken);
    else
        return null;
}

module.exports = {
    getParameterByName: getParameterByName,
    isInvitation: isInvitation,
    hasStateToken: hasStateToken,
    getStateToken: getStateToken,
};