module.exports = {
  'registration.form.title': 'F5 계정 만들기',
  'registration.form.subtitle':
    '지금 바로 가입하여 맞춤화된 콘텐츠, 다운로드 및 리소스에 액세스하고 모든 오퍼링을 간편하게 관리하세요.',
  'registration.form.submit': '가입',
  'registration.complete.desc':
    'F5 계정을 만드신 것을 축하합니다! {0}에서 이메일을 확인하시고 링크를 클릭하여 등록을 확인하고 완료하세요.',
  'mids.signin.title': '돌아오신 것을 환영합니다!',
  'mids.signin.subtitle': '{0}를 계속 사용하려면 로그인하세요',
  'primaryauth.username.placeholder': '이메일',
  'primaryauth.username.input': '이메일 입력',
  'primaryauth.password.input': '비밀번호 입력',
  'enroll.choices.title': '2단계 인증 설정',
  'enroll.choices.description': '보안을 약화해서는 안 됩니다. 사이버 위협으로부터 계정을 강화하세요.',
  'mids.mfa.enroll.google.title': 'Google Authenticator 설정',
  'mids.mfa.enroll.google.subtitle': 'QR 또는 영숫자 코드를 통해 앱에 연결합니다.',
  'enroll.totp.mids.download.label': '{0} 다운로드',
  'enroll.totp.mids.downloadApp': '모바일 장치에 <b>{1}에서 {0} 다운로드</b>',
  'mids.download': '다운로드',
  'mids.enroll.google.scanBarcode.description': '{0} 실행하고 "+" 아이콘을 누른 다음 "바코드 스캔"을 선택합니다.',
  'enroll.totp.cannotScan': '또는 설정 키를 입력합니다.',
  'enroll.totp.manualStep.one': 'Google 인증기 앱에서 +를 누른 다음 <b>설정 키 입력</b>을 누릅니다.',
  'enroll.totp.manualStep.two': '이메일 주소와 이 키(공백은 중요하지 않음)를 입력합니다. <b>{0}</b>',
  'enroll.totp.manualStep.three': '<b>시간 기준</b>이 선택되어 있는지 확인합니다.',
  'enroll.totp.manualStep.four': '<b>추가</b>를 눌러 완료합니다.',
  'mids.close': '닫기',
  'mids.mfa.enroll.email.title': '이메일 인증 설정',
  'email.enroll.description': '이메일을 통해 인증 코드를 받습니다.',
  'email.button.send': '코드 보내기',
  'email.setup.description': '{0}으로 전송된 코드를 입력합니다.',
  'email.setup.placeholder': '코드 입력',
  'email.button.resend': '코드 다시 보내기',
  'mfa.verify.email.title': '이메일로 인증',
  'mfa.verify.email.description': '{0}으로 인증 코드 보내기',
  'mfa.verification.email.placeholder': '코드 입력',
  'mfa.challenge.enterCode.placeholder': 'Google Authenticator 인증',
  'mfa.verify.google.title': '앱에서 비밀번호 보기',
  'mfa.verify.google.subtitle': '인증 코드',
  'password.forgot.email.or.username.tooltip': '이메일',
  'password.forgot.email.or.username.placeholder': '이메일 입력',
  'password.forgot.emailSent.title': '이메일 전송됨!',
  'password.forgot.emailSent.desc': '{0}이 기록과 일치하면 비밀번호 재설정을 위한 안내가 포함된 이메일이 전송됩니다.',
};