module.exports = {
  parseSchema: function(schema, onSuccess, onFailure) {
    // Remove all primary attributes from the schema since this will
    // be the app specific registration page.
    schema.profileSchema.properties = {};

    schema.profileSchema.properties.disqusOptIn = {
      type: "string",
      title: "I want to opt into Disqus",
      required: false
    };

    schema.profileSchema.fieldOrder = [
      "disqusOptIn"
    ];

    onSuccess(schema);
  },
  preSubmit: function(postData, onSuccess, onFailure) {
    onSuccess(postData);
  }
};
