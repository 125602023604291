import React from 'react';
import { widgetsInfo } from '../../helpers/widgetsInfo';

const Disclaimer = ({ page, midsNewUIFeatureToggle }) => {
  if (midsNewUIFeatureToggle) {
    return (
      <div className='f5-disclaimer-ui'>
        <div className='f5-disclaimer-content'>
          <div className='f5-disclaimer-wrapper'>
            <p>
              Find sign in and sign up help in the&nbsp;
              <a
                href='https://my.f5.com/manage/s/article/K000089224'
                rel='noopener'
                target='_blank'
              >
                FAQ
                <svg className='f5-disclaimer-svg' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16.2486 7.49906V3.75H12.4995" stroke="#0E6EB9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M16.249 3.75L7.91772 12.0813" stroke="#0E6EB9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M9.58383 6.24902H4.58508C4.36412 6.24902 4.15221 6.3368 3.99597 6.49304C3.83973 6.64928 3.75195 6.86119 3.75195 7.08215V15.4134C3.75195 15.6344 3.83973 15.8463 3.99597 16.0025C4.15221 16.1587 4.36412 16.2465 4.58508 16.2465H12.9163C13.1373 16.2465 13.3492 16.1587 13.5054 16.0025C13.6617 15.8463 13.7495 15.6344 13.7495 15.4134V10.4146" stroke="#0E6EB9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </a>
            </p>
          </div>
          {
            /* Contact Support */
            widgetsInfo[page].disclaimer ? (
              <div className='flex justify-center'>
                <p>
                  {widgetsInfo[page].disclaimer.supportText}&nbsp;
                  <a
                    className='hover:border-b-2 hover:border-[#0E6EB9]'
                    href={widgetsInfo[page].disclaimer.contactLink}
                    rel='noopener'
                  >
                    {widgetsInfo[page].disclaimer.contactText}
                  </a>
                </p>
              </div>
            ) : null
          }
        </div>
      </div>
    );
  }
  else {
    return (
      <div className='ui-widget'>
        <div className='forceCommunityRichText forceCommunityHtmlBlock'>
          <div dir='ltr'>
            <div>
              {/* FAQ */}
              <div className='slds-form-element slds-m-top_xx-large custom-account slds-p-top--medium slds-text-align_center'>
                <label className='lbl-name'>{'Need help? Check out our '}</label>
                <a
                  className='slds-p-left_x-small f5-sales slds-p-left_x-small-no-padding'
                  href='https://cdn.f5.com/websites/account.f5.com/help.html'
                  rel='noopener'
                >
                  FAQ
                </a>
              </div>
              {
                /* Contact Support */
                widgetsInfo[page].disclaimer ? (
                  <div className='slds-form-element slds-m-top_xx-large custom-account slds-p-top--medium slds-text-align_center'>
                    <label className='lbl-name'>
                      {widgetsInfo[page].disclaimer.supportText}
                    </label>
                    <a
                      className='slds-p-left_x-small f5-sales slds-p-left_x-small-no-padding'
                      href={widgetsInfo[page].disclaimer.contactLink}
                      rel='noopener'
                    >
                      {widgetsInfo[page].disclaimer.contactText}
                    </a>
                  </div>
                ) : null
              }
              {/* Privacy Disclaimer */}
              <div className='slds-form-element slds-m-top_xx-large custom-account slds-p-top--medium slds-text-align_center'>
                <label className='lbl-name'>
                  {
                    'The information you provide will be treated in accordance with '
                  }
                </label>
                <a
                  className='slds-p-left_x-small f5-sales slds-p-left_x-small-no-padding'
                  href='https://www.f5.com/company/policies/privacy-notice'
                  rel='noopener'
                >
                  F5 Privacy Notice
                </a>
              </div>
              <div style={{ height: '60px', width: '100%' }} />
            </div>
          </div>
          <div className='clear'></div>
        </div>
      </div>
    );
  }
};

export default Disclaimer;
