const countries = require("../helpers/countries");
const validations = require("../../server/util/validations")

module.exports = {
  parseSchema: function(schema, onSuccess) {
    let countryModifications = {
      enum: { $ref: "#/definitions/base" },
      oneOf: countries
    };
    Object.assign(schema.profileSchema.properties.country, countryModifications);
    
    schema.profileSchema.properties.password.maxLength = 72;
    
    schema.profileSchema.fieldOrder = [
      "firstName",
      "lastName",
      "email",
      "country",
      "password"
    ];
    
    onSuccess(schema);
  },
  preSubmit: function(postData, onSuccess, onFailure) {
    let email = postData.email; 
    if (validations.isEmailF5Domain(email)) {
      var error = {
        "errorSummary": "API Error",
        "errorCauses": [
          {
            "errorSummary": "model.validation.field.invalid.format.email.f5",
            "reason": "model.validation.field.invalid.format.email.f5",
            "resource": "login",
            "property": "email",
            "arguments": []
          }
        ]
      };
      onFailure(error);
      return;
    }
    let simpleEmailValidationRegex = new RegExp("\\S+@\\S+\\.\\S");
    if(!simpleEmailValidationRegex.test(email)){
      var error = {
        "errorSummary": "API Error",
        "errorCauses": [
          {
            "errorSummary": "model.validation.field.invalid.format.email",
            "reason": "model.validation.field.invalid.format.email",
            "resource": "login",
            "property": "email",
            "arguments": []
          }
        ]
      };
      onFailure(error);
    }else{
      onSuccess(postData);
    }
  }
};