import React, { useEffect } from 'react';

const RedirectF5Home = ({}) => {
  useEffect(() => {
    window.location.href = 'https://www.f5.com/';
  }, []);

  return null;
}

export default RedirectF5Home;