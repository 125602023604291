const registration = require("./baseRegistration");
const registrationF5Labs = require("./f5LabsRegistration");
const { getStateToken } = require("./mainHelpers");
let RedirectManager = require("./RedirectManager");
const redirectManager = RedirectManager(window.ENVIRONMENT_CONFIG, window.localStorage);
let redirectIdpUrl=redirectManager.getIdpRedirectUrl(window.location.search)
const { getLanguagesOverride } = require('../helpers/languagesOverride');

function isRegistrationAllowed(){
  if(ENVIRONMENT_CONFIG.spaRootUrl === 'cleo')
    return false;
  return true;
}

module.exports = {
  useClassicEngine: true,
  spaRootUrl: ENVIRONMENT_CONFIG.spaRootUrl,
  baseUrl: ENVIRONMENT_CONFIG.oktaBaseUrl,
  el: "#okta-login-container",
  brandName: 'F5',
  i18n: getLanguagesOverride(),
  stateToken: getStateToken(),
  features: {
      idpDiscovery: true,
      router: true,
      autoPush: true,
      showPasswordToggleOnSignInPage: true,
      rememberMe: false,
      multiOptionalFactorEnroll: true,
      registration: isRegistrationAllowed(),
      selfServiceUnlock: true,
      showPasswordRequirementsAsHtmlList: true,
  },
  idpDiscovery: {
    requestContext: redirectIdpUrl
  },
  assets: {
      baseUrl: "/"
  },
  helpLinks: {
      help: "https://cdn.f5.com/websites/account.f5.com/help.html"
  },
  registration,
  registrationF5Labs
};
