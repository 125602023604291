// widgetsInfo is a JSON that must contain the basic widget information to
// customize the widget like title, description for the page, the logo info
// and the specific support contact, and additional privacy links
export const widgetsInfo = {
  root: {
    title: 'F5 | Login',
    desc: '',
    logo: {
      src: 'https://cdn.f5.com/webcommon/logo/logo.svg',
      link: 'https://www.f5.com/',
      logoClass:'',
      sideText: '',
    },
    disclaimer: {
      supportText:
        "Can't find the answers? Contact your F5 sales representative or send a message to ",
      contactText: 'F5 Support and Sales',
      contactLink: 'https://www.f5.com/company/contact',
    },
    aditionalPrivacy: {
      text: '',
      link: '',
    },
  },
  udf: {
    title: 'Unified Demonstration Framework Login | F5',
    desc: "Login to F5's unified demo framework to access training labs for F5 products.",
    logo: {
      src: '/img/udf-logo.png',
      link: 'https://udf.f5.com/',
      logoClass:'udf-logo',
      sideText: 'Unified Demonstration Framework',
    },
    disclaimer: {
      supportText:
        "Can't find the answers? Contact ",
      contactText: 'UDF support',
      contactLink: 'https://help.udf.f5.com/en/articles/3832165-how-to-join-an-f5-training-course',
    },
  },
  myprofile: {
    title: 'MyProfile Login | F5',
    logo: {
      src: 'https://cdn.f5.com/webcommon/logo/logo.svg',
      link: 'https://www.f5.com/',
      sideText: 'MyProfile',
    },
    disclaimer: {
      supportText:
        "Can't find the answers? Contact your F5 sales representative or send a message to ",
      contactText: 'F5 Support and Sales',
      contactLink: 'https://www.f5.com/company/contact',
    },
  },
  f5labs: {
    title: 'F5Labs Login | F5',
    logo: {
      src: '/img/f5labs-logo.svg',
      link: 'https://f5.com/labs',
    },
    disclaimer: {
      supportText:
        "Can't find the answers? Contact your F5 sales representative or send a message to ",
      contactText: 'F5 Support and Sales',
      contactLink: 'https://www.f5.com/company/contact',
    },
  },
  everyonesocial:{
    title: 'EveryoneSocial Login | F5',
    desc: " Login to EveryoneSocial, F5's employee advocacy platform, where you can find and share marketing, sales, and recruiting content.",
    logo: {
      src: '/img/es-logo.png',
      link: 'https://f5.everyonesocial.app',
      logoClass:'everyonesocial-logo',
    },
    disclaimer: {
      supportText:
      "Can't find the answers? Contact ",
      contactText: 'F5 Global Social Media',
      contactLink: 'mailto:F5GlobalSocialMedia@f5.com',
    },
  },
  ihealth2:{
    title: 'iHealth Login | F5',
    desc: "Login to iHealth to manage QKViews and access the iHealth REST API.",
    logo: {
      src: 'https://cdn.f5.com/webcommon/logo/logo.svg',
      link: 'https://www.f5.com/',
      sideText:'iHealth'
    },
  },
  ihealth:{
    title: 'iHealth Login | F5',
    desc: "Login to iHealth to manage QKViews and access the iHealth REST API.",
    logo: {
      src: 'https://cdn.f5.com/webcommon/logo/logo.svg',
      link: 'https://www.f5.com/',
      sideText:'iHealth'
    },
    disclaimer: {
      supportText:
        "Need help? Send a message to the iHealth team at ",
      contactText: 'ihealth@f5.com',
      contactLink: 'mailto:ihealth@f5.com',
    },
  },
  advocacy:{
    title: 'Sprinklr Advocacy Login | F5',
    desc: "Login to Sprinklr Advocacy, F5's employee advocacy platform, where you can find and share marketing, sales, and recruiting content.",
    logo: {
      src: 'https://cdn.f5.com/webcommon/logo/logo.svg',
      link: 'https://www.f5.com/',
      sideText:'Sprinklr Advocacy'
    },
  },
  myf5: {
    title: 'MyF5 Login | F5',
    desc: 'Login to MyF5, a tool for viewing and managing your F5 software subscriptions as well as BIG-IP VE subscription and NGINX registration keys.',
    logo: {
      src: 'https://cdn.f5.com/webcommon/logo/logo.svg',
      link: 'https://my.f5.com/manage/s/',
      sideText:'MyF5',
    },
  },
  learnf5: {
    title: 'LearnF5 Login | F5',
    desc: 'Login to LearnF5, where you will find learning resources to help you configure, manage, and optimize your F5 products and services.',
    logo: {
      src: 'https://cdn.f5.com/webcommon/logo/learn-f5-logo.svg',
      link: 'https://f5u.csod.com',
    }
  },
  community: {
    title: 'DevCentral Login | F5',
    desc: "Login to DevCentral, F5's online community of technical peers dedicated to learning, exchanging ideas, and solving problems - together.",
    logo: {
      src: '/img/DC-Logo.svg',
      link: 'https://devcentral.f5.com',
    },
    disclaimer: {
      supportText:
        "Can't find the answers? Contact ",
      contactText: 'DevCentral',
      contactLink: 'https://community.f5.com/t5/devcentral-news/support-and-help-for-devcentral-and-offline-contact/ta-p/291959',
    },
  },
  partnercentral: {
    title: 'Partner Central Login | F5',
    desc:
      'Login to F5 Partner Central for resellers & cloud service providers. Access product resources, announcements, promotions, technical training, & qualified leads.',
    logo: {
      src: '/img/f5-partner-central-lockup.svg',
      link: 'https://partnercentral.f5.com',
    },
    disclaimer: {
      supportText:
        "Can't find the answers? Contact your F5 sales representative or send a message to ",
      contactText: 'F5 Support and Sales',
      contactLink: 'https://www.f5.com/company/contact',
    },
  },
  cleo: {
    title: 'Cleo Secure Files Login | F5',
    desc:
      'Login to Cleo Secure Files to securely share files for your MyF5 support cases with F5 Support.',
    logo: {
      src: '',
      link: '',
    },
    disclaimer: {
      supportText:
        "Can't find the answers? Contact your F5 sales representative or send a message to ",
      contactText: 'F5 Support and Sales',
      contactLink: 'https://www.f5.com/company/contact',
    },
  },
  rockwell: {
    title: 'Rockwell Login | F5',
    desc: 'Login to Rockwell.',
    logo: {
      src: 'https://cdn.f5.com/webcommon/logo/logo.svg',
      link: 'https://my.f5.com/manage/s/',
      sideText:'Rockwell',
    },
  },
  error: {
    title: 'F5 | Error',
    logo: {},
  }
};
