const fetch = require('cross-fetch');

/**
 * Returns true or false indicating whether the specified user is a member
 * of the app's Okta group and has all the app's required attributes
 * 
 * @param {string} userId Current user's id.
 * @param {RedirectManager} redirectInfo localStorage relayState item.
 */
function validateAppUser(userId, redirectInfo) {
  return new Promise(function(resolve, reject) {
    let spaRootUrl = ENVIRONMENT_CONFIG.spaRootUrl;
    if (!spaRootUrl) {
      if (redirectInfo && redirectInfo.appBaseUrl) {
        spaRootUrl = redirectInfo.appBaseUrl;
      } else {
        var error = new Error("Could not determine which app user intends to log in to.");
        reject(error);
        return;
      }
    }
    let usersGroupsUrl = "/" + spaRootUrl + "/api/users/" + userId + "/groups";
    fetch(usersGroupsUrl, { mode: "cors", credentials: "include" })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        var error = new Error(response.statusText);
        error.response = response;
        throw error;
      }
    })
    .then(respBody => {
      resolve(respBody);
    })
    .catch(error => {
      if (error) {
        reject(error);
      }
    });
  });
}

module.exports = validateAppUser;