import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './reactComponents/App.js';
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

const loadStyles = () => {
  let cssUrls;
  let midsNewUIFeatureToggle = localStorage.getItem('newUIFeature');
  if (midsNewUIFeatureToggle === null) {
    midsNewUIFeatureToggle = true;
  } else {
    midsNewUIFeatureToggle = midsNewUIFeatureToggle === 'true';
  }
  
  if (midsNewUIFeatureToggle) {
    cssUrls = ['/css/mids-ui.css', '/css/mids-mfa.css'];
  } else {
    cssUrls = ['/css/mids-mfa.css', '/css/okta-sign-in.min.css', '/css/iam.css',
      '/css/mids-shared.css'];
  }
  const promises = cssUrls.map(url => {
    return new Promise((resolve, reject) => {
      const link = document.createElement('link');
      link.href = url;
      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.onload = () => resolve();
      link.onerror = () => reject(`Failed to load CSS at ${url}`);
      document.head.insertBefore(link, document.head.firstChild);
    });
  });

  return Promise.all(promises);
};

loadStyles()
  .then(() => {
    root.render(
      <App />
    );
  })
  .catch((error) => {
    console.error(error);
  });


