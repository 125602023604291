import React from 'react';
// Old UI
import FooterGroupLinks from './FooterGroupLinks';
import { widgetsInfo } from '../../helpers/widgetsInfo';

const Footer = ({ page, midsNewUIFeatureToggle }) => {
  const links = {
    'Trademarks': 'https://www.f5.com/company/policies/trademarks',
    'Policies': 'https://www.f5.com/company/policies',
    'Privacy': 'https://www.f5.com/company/policies/privacy-policy',
    'California Privacy':
      'https://www.f5.com/company/policies/F5-California-privacy-summary',
    'Do Not Sell My Personal Information':
      'https://www.f5.com/company/policies/privacy-notice#no-sell',
  };
  const linksArray = [];


  for (let key in links) {
    const link = links[key];
    const lowerCaseKey = key.toLowerCase().trim();
    linksArray.push(
      <span className='font-semibold' key={lowerCaseKey}>
        <a href={ link } target="_blank">{ key }</a>
      </span>
    );
  }

  const currentYear = new Date().getFullYear();
  if (midsNewUIFeatureToggle) {
    return (
      <footer className='f5-footer-ui'>
        <div className='f5-footer-body'>
          <div className='f5-footer-links'>
            <span>© {currentYear} F5, Inc. All Rights Reserved</span>
            { linksArray }
            <span id="teconsent"></span>
          </div>
        </div>
      </footer>
    );
  }
  else {
    const policies = {
      'Policies': 'https://www.f5.com/company/policies',
      'Privacy': 'https://www.f5.com/company/policies/privacy-policy',
      'California Privacy':
        'https://www.f5.com/company/policies/F5-California-privacy-summary',
      'Do Not Sell My Personal Information':
        'https://www.f5.com/company/policies/privacy-notice#no-sell',
      'Trademarks': 'https://www.f5.com/company/policies/trademarks',
    };
    const oldLinks = {
      'ABOUT F5': {
        'Corporate Information': 'https://www.f5.com/company/',
        'Newsroom': 'https://www.f5.com/company/news/',
        'Investor Relations': 'https://www.f5.com/company/investor-relations/',
        'Careers': 'https://f5.com/careers/',
        'Contact Information': 'https://www.f5.com/company/contact/',
        'Communication Preferences':
          'https://interact.f5.com/F5-Preference-Center.html?utm_source=optin-f5footer/',
        'Product Certifications': 'https://www.f5.com/company/certifications/',
      },
      'EDUCATION': {
        'Training': 'https://www.f5.com/services/training/',
        'Professional Certification':
          'https://www.f5.com/services/certification/',
        'LearnF5': 'https://f5u.csod.com/',
        'Free Online Training': 'https://f5.com/education/training/free-courses/',
      },
      'F5 SITES': {
        'F5.com': 'https://www.f5.com/',
        'DevCentral': 'https://devcentral.f5.com/',
        'Support Portal': 'https://support.f5.com/csp/home/',
        'Partner Central': 'https://partnercentral.f5.com/',
        'F5 Labs': 'https://www.f5.com/labs/',
      },
    };
  
    let linkGroups = [];
    for (const key in oldLinks) {
      linkGroups.push(
        <FooterGroupLinks key={key} header={key} links={oldLinks[key]} />
      );
    }
    if (
      widgetsInfo[page].aditionalPrivacy &&
      widgetsInfo[page].aditionalPrivacy.text !== ''
    ) {
      policies[widgetsInfo[page][aditionalPrivacy].text] =
        widgetsInfo[page][aditionalPrivacy].link;
    }
  
    let policiesArray = [];
    for (const key in policies) {
      policiesArray.push(
        <li key={key}>
          <a href={policies[key]} target='_blank'>
            {key}
          </a>
        </li>
      );
    }
    policiesArray.push(<li key='teconsent' id='teconsent'></li>);

    return (
      <div className='ui-widget'>
        <div className='f5-footer cF5_footer'>
          <div className='footer-container'>
            {/* Follow us */}
            <div className='slds-grid slds-wrap slds-gutters footer-contact-info slds-p-top_xx-large slds-p-bottom_xx-large'>
              <div className='slds-col slds-size_1-of-2 slds-max-small-size_1-of-1 slds-max-medium-size_1-of-1 slds-large-size_1-of-2 slds-text-align_center'>
                <span>HAVE A QUESTION? </span>
                <span className='footer__contact'>
                    {' | '}&nbsp;
                  <a href='https://www.f5.com/company/contact' target='_blank'>
                    Support and Sales&nbsp;&rsaquo;
                  </a>
                </span>
              </div>
              <div className='slds-col slds-size_1-of-2 footer-icons slds-max-small-size_1-of-1 slds-max-medium-size_1-of-1 slds-large-size_1-of-2'>
                <div className='slds-clearfix'>
                  <div className='slds-float_left'>FOLLOW US</div>
                  <div className='slds-float_left slds-m-left_medium'>
                    <ul className='slds-list_horizontal slds-has-inline-block-links_space'>
                      <li className='slds-p-left_large'>
                        <a href='https://twitter.com/f5' target='_blank'>
                          <img
                            alt='Twitter'
                            heigth='19px'
                            width='19px'
                            src='/img/twitter.svg'
                          />
                        </a>
                      </li>
                      <li className='slds-p-left_large'>
                        <a
                          href='https://www.linkedin.com/company/f5'
                          target='_blank'
                        >
                          <img
                            alt='LinkedIn'
                            heigth='21px'
                            width='21px'
                            src='/img/linkedin.svg'
                          />
                        </a>
                      </li>
                      <li className='slds-p-left_large'>
                        <a
                          href='https://www.facebook.com/f5incorporated'
                          target='_blank'
                        >
                          <img
                            alt='Facebook'
                            style={{height:'23px',width:'11px'}}
                            src='/img/facebook.svg'
                          />
                        </a>
                      </li>
                      <li className='slds-p-left_large'>
                        <a
                          href='https://www.youtube.com/f5networksinc'
                          target='_blank'
                        >
                          <img
                            alt='Youtube'
                            heigth='23px'
                            width='23px'
                            src='/img/youtube.svg'
                          />
                        </a>
                      </li>
                      <li className='slds-p-left_large'>
                        <a href='https://devcentral.f5.com/' target='_blank'>
                          <img
                            alt='DevCentral'
                            heigth='28px'
                            width='28px'
                            src='/img/DC-JP-Outline-White.svg'
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* Links */}
            <div className='slds-gutters list-info slds-p-top_large'>
              <div className='custom-width slds-grid slds-wrap'>{linkGroups}</div>
            </div>
            {/* Privacy */}
            <div className='privacy-policy slds-p-left_xx-small slds-p-top_xx-large slds-p-bottom_x-large'>
              <div className='slds-wrap slds-text-align_center'>
                <div className='slds-max-small-size_1-of-1 slds-wrap'>
                  &copy;
                  <span id='mids-copyright-year'>
                    {currentYear}F5, Inc. All rights reserved.
                  </span>
                </div>
                <div className='slds-max-small-size_1-of-1 slds-wrap'></div>
                <ul className='slds-list_horizontal'>{policiesArray}</ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Footer;
