const fetch = require('cross-fetch');

/**
 * Adds a user to an Okta group when they log in.
 * 
 * @param {string} email Current user's email
 * @param {string} app Current app the user is trying to log into
 */
let WithGroup = function (email, app) {
    return new Promise(function (resolve, reject) {
        fetch(`/${app}/api/users/groups`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: email })
        })
        .then(function (response) {
            return response.json();
        })
        .then(function (res) {
            if (res.message && res.message === 'Success') {
                resolve(res);
            } else {
                reject(res);
            }
        })
        .catch(function (error) {
            reject(error);
        });
    });
}

module.exports = WithGroup;