import React from 'react';

const Colorbar = () => {
  return (
    <div className='f5-colorbar'>
      <span className='tangerine-colorbar'></span>
      <span className='red-colorbar'></span>
      <span className='purple-colorbar'></span>
      <span className='blue-colorbar'></span>
      <span className='dark-purple-colorbar'></span>
    </div>
  );
};

export default Colorbar;
