import React from 'react';
import '../styles/index.css';
import { createRoot } from 'react-dom/client';
import LoadingDialog from '../reactComponents/shared/LoadingDialog'

const injectReact = (typeOfSkipFunction) => {
  const container = document.querySelector('body');
  if (!container)
    window.location.href = ENVIRONMENT_CONFIG.f5ErrorPageUrl;
  const newDiv = document.createElement('div');
  newDiv.classList.add('react-container');
  container.prepend(newDiv);
  const root = createRoot(newDiv);

  if (typeOfSkipFunction === 'loading') {
    root.render(
      <LoadingDialog
        open={true}
        bodyText='Please wait a moment while we verify your account'
        headerText='Verifying'
        id='loading-container'
      />
    );
  }
};

export default injectReact;
