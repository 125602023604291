module.exports = {
  'registration.form.title': 'Erstellen Sie ein Benutzerkonto bei F5',
  'registration.form.subtitle': 
    'Melden Sie sich noch heute an und sichern Sie sich den Zugang zu personalisierten Inhalten, Downloads und Ressourcen und um all Ihre Angebote reibungslos zu verwalten.',
  'registration.form.submit': 'Anmelden',
  'registration.complete.desc': 
    'Herzlichen Glückwunsch zur Erstellung Ihres F5-Kontos! Bitte prüfen Sie Ihr E-Mail-Postfach unter {0} und klicken Sie auf den Link, um Ihre Registrierung zu überprüfen und abzuschließen.',
  'mids.signin.title': 'Herzlich willkommen zurück!',
  'mids.signin.subtitle': 'Melden Sie sich an, um bei {0} fortzufahren',
  'primaryauth.username.placeholder': 'E-Mail',
  'primaryauth.username.input': 'Geben Sie Ihre E-Mail-Adresse ein',
  'primaryauth.password.input': 'Geben Sie Ihr Passwort ein',
  'enroll.choices.title': '2-Faktor-Authentifizierung einrichten',
  'enroll.choices.description': 'Gehen Sie in Sachen Sicherheit keine Kompromisse ein: Schützen Sie Ihr Konto vor Cyberbedrohungen.',
  'mids.mfa.enroll.google.title': 'Google Authenticator einrichten',
  'mids.mfa.enroll.google.subtitle': 'Verbinden Sie sich mit der App über einen QR- oder alphanumerischen Code.',
  'enroll.totp.mids.download.label': '{0} herunterladen',
  'enroll.totp.mids.downloadApp': '<b>{0} aus dem {1}</b> auf Ihr Mobilgerät herunterladen',
  'mids.download': 'Herunterladen',
  'mids.enroll.google.scanBarcode.description': '{0} starten, tippen Sie auf das „+“-Symbol und wählen Sie dann „Barcode scannen“.',
  'enroll.totp.cannotScan': 'Oder geben Sie den Setup-Schlüssel ein',
  'enroll.totp.manualStep.one': 'Tippen Sie in der Google Authenticator-App auf „+“ und dann auf <b>Setup-Schlüssel eingeben</b>.',
  'enroll.totp.manualStep.two': 'Geben Sie Ihre E-Mail-Adresse und diesen Schlüssel ein (Leerzeichen spielen keine Rolle): <b>{0}</b>',
  'enroll.totp.manualStep.three': 'Achten Sie darauf, dass <b>Zeitbasiert</b> ausgewählt ist.',
  'enroll.totp.manualStep.four': 'Tippen Sie zum Abschluss auf <b>Hinzufügen</b>.',
  'mids.close': 'Schließen',
  'mids.mfa.enroll.email.title': 'E-Mail-Authentifizierung einrichten',
  'email.enroll.description': 'Verifizierungscode per E-Mail erhalten.',
  'email.button.send': 'Code senden',
  'email.setup.description': 'Geben Sie den Code ein, der an {0}.',
  'email.setup.placeholder': 'Geben Sie Ihren Code ein',
  'email.button.resend': 'Code erneut zusenden',
  'mfa.verify.email.title': 'Mit E-Mail-Authentifizierung überprüfen',
  'mfa.verify.email.description': 'Einen Verifizierungscode an {0}',
  'mfa.verification.email.placeholder': 'Geben Sie Ihren Code ein',
  'mfa.challenge.enterCode.placeholder': 'Überprüfung mit Google Authenticator',
  'mfa.verify.google.title': 'Passcode in der App anzeigen',
  'mfa.verify.google.subtitle': 'Verifizierungscode',
  'password.forgot.email.or.username.tooltip': 'E-Mail',
  'password.forgot.email.or.username.placeholder': 'Geben Sie Ihre E-Mail-Adresse ein',
  'password.forgot.emailSent.title': 'E-Mail gesendet!',
  'password.forgot.emailSent.desc':
    'Wenn {0} mit unseren Datenaufzeichnungen übereinstimmt, wurde eine E-Mail mit Anweisungen zum Zurücksetzen Ihres Passworts an diese E-Mail-Adresse gesendet.',
}