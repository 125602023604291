import React from 'react';

const FooterGroupLinks = ({ header, links }) => {
  let linkArray = [];

  for (const key in links) {
    linkArray.push(
      <ul className='slds-has-inline-block-links_space' key={key}>
        <li>
          <a href={links[key]} target='_blank'>
            {key}
          </a>
        </li>
      </ul>
    );
  }
  return (
    <div className='slds-col slds-size_1-of-3 slds-max-small-size_1-of-1'>
      <div className='list-heading slds-p-top_medium slds-p-bottom_medium'>
        {header}
      </div>
      {linkArray}
    </div>
  );
};

export default FooterGroupLinks;