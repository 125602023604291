module.exports = {
  'registration.form.title': 'Create Your F5 Account',
  'registration.form.subtitle': 'Gain access to personalized content, downloads, and resources, and manage all your offerings with ease by signing up today.',
  'registration.form.submit': 'Sign up',
  'registration.complete.desc':
    'Congratulations on creating your F5 account! Please check your email at {0} and click the link to verify and complete your registration.',
  'registration.complete.resend': "Didn't receive the email?<a class='resend'>Resend</a>",
  'mids.signin.title': 'Welcome back!',
  'mids.signin.subtitle': 'Sign in to continue to {0}',
  'primaryauth.username.placeholder': 'Email',
  'primaryauth.username.input': 'Enter your email',
  'primaryauth.password.input': 'Enter your password',
  'enroll.choices.title': 'Set Up 2-Step Authentication',
  'enroll.choices.description':
    "Don't compromise on security. Fortify your account against cyber threats.",
  'mids.mfa.enroll.google.title': 'Set Up Google Authenticator',
  'mids.mfa.enroll.google.subtitle': 'Connect to the app via QR or alphanumeric code.',
  'enroll.totp.mids.download.label': 'Download {0}',
  'enroll.totp.mids.downloadApp': '<b>Download {0} from the {1}</b> onto your mobile device',
  'mids.download': 'Download',
  'mids.enroll.google.scanBarcode.description': 'Launch {0}, tap the "+" icon, then select "Scan barcode."',
  'enroll.totp.cannotScan': 'Or, enter the setup key',
  'enroll.totp.manualStep.one': 'In the Google Authenticator app, tap the +, then tap <b>Enter a setup key</b>.',
  'enroll.totp.manualStep.two': "Enter your email address and this key (spaces don't matter): <b>{0}</b>",
  'enroll.totp.manualStep.three': 'Make sure <b>Time based</b> is selected.',
  'enroll.totp.manualStep.four': 'Tap <b>Add</b> to finish.',
  'mids.close': 'Close',
  'mids.mfa.enroll.email.title': 'Set Up Email Authentication',
  'email.enroll.description': 'Receive a verification code via email.',
  'email.button.send': 'Send code',
  'email.setup.description': 'Enter the code that was sent to {0}.',
  'email.setup.placeholder': 'Enter your code',
  'email.button.resend': 'Resend code',
  'mfa.verify.email.title': 'Verify with Email Authentication',
  'mfa.verify.email.description': 'Send a verification code to {0}',
  'mfa.verification.email.placeholder': 'Enter your code',
  'mfa.challenge.enterCode.placeholder': 'Verification code',
  'mfa.verify.google.title': 'Verify with Google Authenticator',
  'mfa.verify.google.subtitle': 'View passcode in the app',
  'password.forgot.email.or.username.tooltip': 'Email',
  'password.forgot.email.or.username.placeholder': 'Enter your email',
  'password.forgot.emailSent.title': 'Email sent!',
  'password.forgot.emailSent.desc':
    'If {0} matches our records, an email has been sent with instructions on resetting your password.',
}